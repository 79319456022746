import * as React from "react"
import { PageProps } from "gatsby"

import Layout from "~/components/layout"
import HeroSection from "~/components/sections/contact/hero"
import SEO from "~/components/seo"

const ContactUsPage: React.FC<PageProps> = () => (
  <Layout>
    <SEO title="Contact" />
    <HeroSection />
  </Layout>
)

export default ContactUsPage
