import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { useForm } from "react-hook-form"
import axios from "axios"
import classNames from "classnames"
import * as styles from "./index.module.scss"
import Container from "~/components/ui/container"
import Button from "~/components/ui/button"

const HeroSection: React.FC = () => {
  const [isAnimated, setIsAnimated] = useState(false)

  useEffect(() => {
    setIsAnimated(true)
  }, [setIsAnimated])

  useEffect(() => {
    const grecaptchaObj = (window as any).grecaptcha

    if (grecaptchaObj?.render) {
      grecaptchaObj?.render(document.getElementById("recaptcha"), {
        sitekey: process.env.GATSBY_CAPTCHA_SITE_KEY,
      })
    }
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    shouldFocusError: false,
  })

  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [serverError, setServerError] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "contact_bg.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
    }
  `)

  const onSubmit = async data => {
    try {
      const captchaResponse = (window as any).grecaptcha?.getResponse()
      if (captchaResponse) {
        setIsLoading(true)
        const form = new FormData()

        form.append(
          "g-recaptcha-response",
          (window as any).grecaptcha?.getResponse()
        )
        form.append("firstName", data.firstName)
        form.append("lastName", data.lastName)
        form.append("email", data.email)
        form.append("message", data.message)
        form.append("_gotcha", data._gotcha)

        await axios.post(process.env.GATSBY_CONTACT_FORM_URL, form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })

        setIsLoading(false)
        setIsSuccess(true)
        setTimeout(() => setIsSuccess(false), 2000)
        reset()
      }
    } catch (error) {
      setIsLoading(false)
      setServerError(true)
      setTimeout(() => setServerError(false), 2000)
    }
  }
  return (
    <section className={styles.root}>
      <div className={styles.background__img}>
        <GatsbyImage
          alt="Urus office building outside"
          image={data.file.childImageSharp.gatsbyImageData}
          objectFit="cover"
          objectPosition="50% 50%"
        />
      </div>
      <div
        className={classNames(styles.animation, {
          [styles.animation__animated]: isAnimated,
        })}
      ></div>
      <div className={styles.container}>
        <h1 className={classNames("headline--decorated", styles.headline)}>
          <span>Connect</span> With us
        </h1>
        <div className={styles.form}>
          <p className={styles.form__paragraph}>
            We look forward to hearing from you
          </p>
          <h2 className={styles.form__headline}>Contact</h2>

          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.form__row}>
                <div className={styles.form__col}>
                  <div
                    className={classNames(styles.form__input, {
                      [styles.form__error]: errors.firstName,
                    })}
                  >
                    <input
                      {...register("firstName", { required: true })}
                      type="text"
                      placeholder="First Name"
                      aria-label="First Name"
                    />
                  </div>
                </div>
                <div className={styles.form__col}>
                  <div
                    className={classNames(styles.form__input, {
                      [styles.form__error]: errors.lastName,
                    })}
                  >
                    <input
                      {...register("lastName", { required: true })}
                      type="text"
                      placeholder="Last Name"
                      aria-label="Last Name"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.form__row}>
                <div
                  className={classNames(styles.form__input, {
                    [styles.form__error]: errors.email,
                  })}
                >
                  <input
                    {...register("email", { required: true })}
                    type="text"
                    placeholder="Email"
                    aria-label="Email"
                  />
                </div>
              </div>
              <div className={styles.form__row}>
                <div
                  className={classNames(styles.form__textarea, {
                    [styles.form__error]: errors.message,
                  })}
                >
                  <textarea
                    {...register("message", { required: true })}
                    placeholder="Message"
                    aria-label="Message"
                  ></textarea>
                </div>
              </div>
              <div
                className={classNames(styles.form__checkbox, {
                  [styles.form__error]: errors.consent,
                })}
              >
                <input
                  {...register("consent", {
                    validate: value => {
                      return value
                    },
                  })}
                  type="checkbox"
                />
                <label htmlFor="consent">
                  I consent to urus.org collecting my details through this form.
                </label>
              </div>

              <input
                type="text"
                {...register("_gotcha")}
                style={{ display: "none" }}
              />

              <div
                tabIndex={0}
                className={classNames("g-recaptcha", styles.captcha)}
                id="recaptcha"
              ></div>
              <div className={styles.form__button}>
                <Button type="submit">
                  {isLoading ? "Submitting..." : "Send"}
                </Button>
              </div>
              {serverError && (
                <p className={styles.error}>Something Went Wrong.</p>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroSection
