// extracted by mini-css-extract-plugin
export var animation = "index-module--animation--5e9dd";
export var animation__animated = "index-module--animation__animated--7acae";
export var background__img = "index-module--background__img--9f9fc";
export var captcha = "index-module--captcha--3a0ee";
export var container = "index-module--container--817ed";
export var error = "index-module--error--2595f";
export var form = "index-module--form--deac3";
export var form__button = "index-module--form__button--a53ce";
export var form__checkbox = "index-module--form__checkbox--5b5a9";
export var form__col = "index-module--form__col--604b2";
export var form__error = "index-module--form__error--9eae5";
export var form__headline = "index-module--form__headline--7faf8";
export var form__input = "index-module--form__input--feccb";
export var form__paragraph = "index-module--form__paragraph--40895";
export var form__row = "index-module--form__row--2a229";
export var form__textarea = "index-module--form__textarea--236d4";
export var headline = "index-module--headline--4d948";
export var root = "index-module--root--004f8";